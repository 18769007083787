<template>
  <section>
    <SelectMaterial @selected="materialSelected"/>
    <div>
      {{ $t('way_of_passing') }}
      <SelectGroup class="default d-inline-block ml-3"
                   :hasNullOption="false"
                   v-model="type"
                   @input="typeChanged"
                   :options="types"/>
    </div>
    <a class="btn-whole-add btn-whole-add-dashed" @click="addSelfstudy">
      <span class="svg-wrapper mr-3"><PlusSvg class="svg-icon-sm"/></span>{{ $t('add_material') }}
    </a>
    <draggable v-model="student.selfstudy.data" @change="reorder">
      <div class="stud-history-teaser"
           v-for="(d, dind) in student.selfstudy.data"
           :key="'selfdata-'+dind">
        <b-row class="align-items-center">
          <b-col lg cols="12">
<!--            <div>{{ d.id }} {{ d.material.title }} - {{ d.availableForStudy }}</div>-->
            <div v-if="d.material">{{ d.material.title }}</div>
            <div class="mt-2" v-if="d.finished || d.reviewed">
              <span v-if="d.finished" class="done-span mr-2">{{ $t('done') }}</span>
              <span v-if="d.reviewed" class="reviewed-span">{{ $t('reviewed') }}</span>
            </div>
            <div v-else>
              <span class="closed-span mt-2" v-if="!d.availableForStudy"><LockSvg class="mr-2"/>{{ $t('lesson_closed') }}</span>
            </div>
          </b-col>
          <!--        <b-col lg="2" cols="12">-->
          <!--          <div>-->
          <!--            <span v-if="d.finished">{{ $t('done') }}</span>-->
          <!--            <span class="text-danger" v-else>{{ $t('not_done') }}</span>-->
          <!--          </div>-->
          <!--          <div class="text-success" v-if="d.reviewed">{{ $t('reviewed') }}</div>-->
          <!--        </b-col>-->
          <b-col cols="auto">
            <div class="text-muted date">
              <router-link class="btn-themed btn-white btn-sm"
                           :to="{ name: 'SelfStudyLesson', params: { id: d.material_id, studentid: student.id }}">
                {{ d.finished && !d.reviewed ? $t('check') : $t('view') }}
              </router-link>
            </div>
          </b-col>
          <b-col lg="auto" cols="6" class="text-right">
            <b-dropdown right no-caret menu-class="noscrollbar" variant="btn" class="">
              <template v-slot:button-content>
                <a class=" btn-themed btn-white btn-sm pointer">
                  <MoreSvg class="d-inline-block"/>
                </a>
              </template>
              <b-dropdown-item @click="makeStudyLessonOpened(d.material_id)" v-if="!d.availableForStudy">
                <UnlockSvg class="mr-2"/>{{ $t('open_access') }}
              </b-dropdown-item>
              <b-dropdown-item @click="deleteSelfstudy(d.material_id)" >
                <TrashSvg class="svg-black mr-2"/>{{ $t('delete') }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </div>
    </draggable>
    <div v-if="student.selfstudy.data.length == 0" class="pt-3">
      {{ $t('no_data_available') }}
    </div>
  </section>
</template>

<script>

import PlusSvg from '@/assets/svg/plus.svg'
import SelectMaterial from '@/views/parts/class/SelectMaterial'
import TrashSvg from '@/assets/svg/trash.svg'
import {StudentsService} from "../../../../../services/api.service"
import MoreSvg from "@/assets/svg/dots.svg"
import UnlockSvg from "@/assets/svg/unlock.svg"
import LockSvg from "@/assets/svg/lock.svg"
import SelectGroup from "../../../../parts/general/form/SelectGroup"
import draggable from 'vuedraggable'

export default {
  name: 'StudentSelfStudy',
  components: {
    PlusSvg,
    TrashSvg,
    MoreSvg,
    LockSvg,
    UnlockSvg,
    SelectMaterial,
    draggable,
    SelectGroup
  },
  data() {
    return {
      type: 'opened',
      types: [
        { id: 'opened', title: 'opened'},
        { id: 'as_you_go', title: 'as_you_go'},
      ]
    }
  },
  metaInfo() {
    return {
      title: this.$t('selfstudy'),
    }
  },
  computed: {
    ids() {
      if(!this.student || !this.student.selfstudy) return []
      return this.student.selfstudy.data.map(a => a.id)
    }
  },
  props: {
    student: {}
  },
  mounted() {
    if(this.student.selfstudy.data.filter(x => x.opened === 0).length) {
      this.type = 'as_you_go'
    } else {
      this.type = 'opened'
    }
  },
  methods: {
    addSelfstudy() {
      this.$bvModal.show('selectmaterialmodal')
    },
    materialSelected(material) {
      let materialId = material.id
      StudentsService.addSelfStudy(this.student.id, materialId).then(res => {
        this.student.selfstudy = res.data
        this.reorder()
      })
    },
    deleteSelfstudy(materialId) {
      this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
        StudentsService.removeSelfStudy(this.student.id, materialId).then(res => {
          this.student.selfstudy = res.data
          this.reorder()
        })
      })
    },
    async reorder() {
      // if(this.type === 'as_you_go') {
        await StudentsService.changeSelfStudyOrder(this.student.id, this.ids, this.type)
        this.$emit('refresh')
      // }
    },
    async typeChanged() {
      await StudentsService.changedSelfStudyType(this.student.id, this.type, this.ids)
      this.$emit('refresh')
    },
    async makeStudyLessonOpened(mid) {
      await StudentsService.makeStudyLessonOpened(this.student.id, mid,1)
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped lang="scss">
.stud-history-teaser {
  padding: 24px 0;
  border-bottom: 1px solid $border-gray;
  font-size: 16px;
  .date {
    font-size: 14px;
  }
}
.done-span, .reviewed-span, .closed-span {
  font-size: 14px;
  border-radius: 100px;
  padding: 3px 10px;
  display: inline-block;
}
.done-span {
  background: #F5F5F5;
  color: #7E828C;
}
.reviewed-span {
  background: #E0FBEB;
  color: #27AE60;
}
.closed-span {
  color: #B8BAC1;
  border: 1px solid #DADCE2;
}
.svg-black {
  width: 20px;
  height: 20px;
}
@media(max-width: 768px) {
  .stud-history-teaser {
    font-size: 14px;
  }
}
</style>
